import React from 'react'
import MainHeader from '../home/Header'
import MainFooter from '../home/Footer'
const pageHeader = (<header className="secondaryhead">
<div className="container h-100">
  <div className="row h-100">
    <div className="col-12 my-auto">
      <div className="header-content mx-auto">
        <h1 className="mb-1">Terms & Condition 
</h1>
        
      
      </div>
    </div>
  
  </div>
</div>
</header> );
 
const Terms = () => (
 <React.Fragment>  
<MainHeader /> 
  <div>
     {pageHeader}
      <section className="cms-pages">
      <div className="container">
                <p> &nbsp;</p>
  <p> &nbsp;</p>
        <p> NinjaEats does not endorse any restaurant or any promo in particular. All terms and conditions pertaining to promo codes, its value, usage, validity are withheld by the respective restaurant. NinjaEats will not be liable for any dispute arising from the use of information or promo codes found on the website.



</p>
      <p  style={{ textAlign : "center"}}>  <img src={"/img/terms.jpg"} className="image-max-width"  alt={"About us"} style={{ width: "100%" }} /> </p>  
 
 
   
      </div>
      </section>
         </div>
		 	 <MainFooter />
		   </React.Fragment> 
)


export default Terms