import React, { Component } from 'react'
import { Collapse, Card, CardHeader, CardBody, CardText } from 'reactstrap';
import MainHeader from '../home/Header'
import MainFooter from '../home/Footer'
class Faq extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: 0 };
    }
    
componentDidMount() {
  window.scrollTo(0, 0)
}
    toggle(e) {
      let event = e.target.dataset.event;
      this.setState({ collapse: this.state.collapse === Number(event) ? 0 : Number(event) });
    }
    render() {
      const { collapse} = this.state;
      const pageHeader = (<header className="secondaryhead">
                      <div className="container h-100">
                        <div className="row h-100">
                          <div className="col-12 my-auto">
                            <div className="header-content mx-auto">
                              <h1 className="mb-1">Frequently Asked Questions</h1>
							  {/* <p>Any special message will show here</p>  */}                           
                            </div>
                          </div>
                        
                        </div>
                      </div>
                      </header> ); 
      return (
	   <React.Fragment>    
   <MainHeader />
        <div>
        {pageHeader}
          <section className="cms-pages faq-page">
          <div className="container">
          <div className="col-md-8 offset-md-2">
          <h2>For Consumers</h2>
           
          <Card style={{ marginTop: '1rem' }}  key={1}>
            <CardHeader onClick={this.toggle} data-event={1}>1. Do I need to pay to use The NinjaEats?</CardHeader>
            <Collapse isOpen={collapse === 1}>
            <CardBody>
               <CardText>a.	Nope, the service is completely free. We aim to curate the best promo codes and deals available in Singapore for you..</CardText>
            </CardBody>   
            </Collapse>       
          </Card>    
          <Card style={{ marginTop: '1rem' }} key={2}>
           <CardHeader onClick={this.toggle} data-event={2}>2.	Can I use a portion of the promo code discount?</CardHeader>
          <Collapse isOpen={collapse === 2}>
          <CardBody>
                <CardText>a.	No, each promo code is for single use.</CardText>         
           </CardBody>   
           </Collapse>       
         </Card>
          <Card style={{ marginTop: '1rem' }} key={3}>
           <CardHeader onClick={this.toggle} data-event={3}>3.	There are tags on the promo code such as Delivery, Dine In and etc, what does that mean?</CardHeader>
           <Collapse isOpen={collapse === 3}>
           <CardBody>
                <CardText>a.	This means that you are able to use this promo code with the following services that the restaurant of your choice is offering. Example; If a promo code is meant for Pick up, you will be entitled to use it for a Pick up order only.</CardText>          
           </CardBody>    
           </Collapse>      
         </Card>
          <Card style={{ marginTop: '1rem' }} key={4}>
           <CardHeader onClick={this.toggle} data-event={4}>4.	Will the Promo code get expired</CardHeader>
           <Collapse isOpen={collapse === 4}>
           <CardBody>
                <CardText>a.	Yes, please do click on the promo code to read more details on the usage terms, validity and more.</CardText>          
           </CardBody>   
           </Collapse>   
         </Card> 
          <Card style={{ marginTop: '1rem' }} key={5}>
           <CardHeader onClick={this.toggle} data-event={5}>5.	Will I be able to share this promo code with my friends?</CardHeader>
           <Collapse isOpen={collapse === 5}>
           <CardBody>
                <CardText>a.	Yes, by all means do share the love with your friends and family.</CardText>          
           </CardBody>   
           </Collapse>   
         </Card> 
          <Card style={{ marginTop: '1rem' }} key={6}>
           <CardHeader onClick={this.toggle} data-event={6}>6.	The restaurant that I am looking for is not in this list, what can I do?</CardHeader>
           <Collapse isOpen={collapse === 6}>
           <CardBody>
                <CardText>a.	Do leave us a note in our contact us page and we will be in touch with your favourite restaurant to offer you a deal.</CardText>          
           </CardBody> 
           </Collapse>   
         </Card> 
          <Card style={{ marginTop: '1rem' }} key={7}>
           <CardHeader onClick={this.toggle} data-event={7}>7.	If I have trouble using the code who should I contact?</CardHeader>
           <Collapse isOpen={collapse === 7}>
           <CardBody>
                <CardText>a.	You can leave us a note in our contact us page or contact the restaurant directly. Either of us will be able to help you out.</CardText>          
           </CardBody>      
           </Collapse>
         </Card> 
          <Card style={{ marginTop: '1rem' }} key={8}>
           <CardHeader onClick={this.toggle}  data-event={8}>8.	Do I get additional discounts if I use this promo code and a specific credit card?</CardHeader>
           <Collapse isOpen={collapse === 8}>
          <CardBody>
                <CardText>a.	The NinjaEats Deals are always on top of which ever discounts or rebates that your credit card is offering you. Time to place some orders</CardText>          
           </CardBody>      
           </Collapse>
         </Card> 
          <h2>For Restaurant Partners</h2>
           <Card style={{ marginTop: '1rem' }} key={9}>
            <CardHeader onClick={this.toggle} data-event={9}>1.	How do I list about my restaurant in your restaurant’s list?</CardHeader>
            <Collapse isOpen={collapse === 9}>
            <CardBody>
                 <CardText>a.	You can simply fill up the “List with us” online form to get your restaurant’s listing page setup.</CardText>          
            </CardBody>  
            </Collapse>    
          </Card> 
           <Card style={{ marginTop: '1rem' }} key={10}>
            <CardHeader onClick={this.toggle} data-event={10}>2.	How do I list my Promo code with The NinjaEats?</CardHeader>
            <Collapse isOpen={collapse === 10}>
            <CardBody>
                 <CardText>a.	Simply fill up the “List with us” online form to have more details on how you too can list your promo code with us.</CardText>          
            </CardBody>     
            </Collapse> 
          </Card> 
           <Card style={{ marginTop: '1rem' }} key={11}>
            <CardHeader onClick={this.toggle} data-event={11}>3.	Do I need to pay to list my restaurant in your restaurant’s list?</CardHeader>
            <Collapse isOpen={collapse === 11}>
            <CardBody>
                 <CardText>a.	No, this service is free</CardText>          
            </CardBody> 
            </Collapse>     
          </Card> 
           <Card style={{ marginTop: '1rem' }} key={12}>
            <CardHeader onClick={this.toggle} data-event={12}>4.	Do I need to pay to list my promo code?</CardHeader>
            <Collapse isOpen={collapse === 12}>
            <CardBody>
                 <CardText>a.	No, however you need to be part of our participating merchants with NinjaOS.</CardText>          
            </CardBody> 
            </Collapse>     
          </Card> 
          {/* <Card style={{ marginTop: '1rem' }} key={13}>
            <CardHeader onClick={this.toggle} data-event={13}>5.	How long does it take list my restaurant’s page?</CardHeader>
            <Collapse isOpen={collapse === 13}>
            <CardBody>
                 <CardText>a.	It would take us 2 days to setup your page.</CardText>          
            </CardBody> 
            </Collapse>    
          </Card>  */}
           <Card style={{ marginTop: '1rem' }} key={14}>
            <CardHeader onClick={this.toggle} data-event={14}>6.	How long does it take to list my promo codes ?</CardHeader>
            <Collapse isOpen={collapse === 14}>
            <CardBody>
                 <CardText>a.	It would take us 10 days to onboard you on your own website with NinjaOS, thereafter all your promo codes will appear instantly as you create them.</CardText>          
            </CardBody>  
            </Collapse>    
          </Card> 
           <Card style={{ marginTop: '1rem' }} key={15}>
            <CardHeader onClick={this.toggle} data-event={15}>7.	Do I pay any commissions for customers referred from the NinjaEats?</CardHeader>
            <Collapse isOpen={collapse === 15}>
            <CardBody>
                 <CardText>a.	No, the service and the referrals are completely free.</CardText>          
            </CardBody>   
            </Collapse>   
          </Card> 
          </div>
          </div>
          </section>
             </div>
			 <MainFooter />
		   </React.Fragment>   
      );
     }
    }     


export default Faq