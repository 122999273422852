import axios from 'axios'
export const FETCH_PROMOTIONS = 'promotions/FETCH_PROMOTIONS'
const API_URL = 'https://ccpl.ninjaos.com/api/'; 
const initialState = {
    items: [],
    imagepath: null,
    loading: true,
    error: null
}

export default function(state = initialState , action) {
  
   switch (action.type){
       case FETCH_PROMOTIONS:
        return {
            ...state,
            items: action.payload,
            imagepath: action.imagepath,
			loading : false
        }
              
       default:
       return state;
   }
}
export function fetchPromotions(){
    return function (dispatch) {
        axios.get(`${API_URL}/promobuddy/getAllPromotionList`)
        .then(response => {
          dispatch({
            type: FETCH_PROMOTIONS,
            payload: response.data.result_set,
            imagepath:  response.data.imagePath,
			loading : false
          });
        })
        .catch((error) => {
          console.log(error);
        })
       
    }
}
