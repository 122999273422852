import React, {useEffect } from 'react'
import MainHeader from '../home/Header'
import MainFooter from '../home/Footer'

const pageHeader = (<header className="secondaryhead">
<div className="container h-100">
  <div className="row h-100">
    <div className="col-12 my-auto">
      <div className="header-content mx-auto">
        <h1 className="mb-1">NinjaEats is here for GOOD</h1>
        
      
      </div>
    </div>
  
  </div>
</div>
</header> );
 
const About = () => (

 <React.Fragment>  

<MainHeader /> 
  
  <div>

    {pageHeader}
      <section className="cms-pages">
      <div className="container">
                <p> &nbsp;</p>
				<h2>For Consumers </h2> 

        <p> The Promo Buddy was launched with just one thing in mind, reaching out to consumers offering them the best deals available in Singapore for Delivery, Takeaway, Dine In and Catering.  </p> 
		<p>
This is a free to use site, so don't just enjoy by yourself, do care to share on your socials and chat. Let everyone enjoy the love of great deals ! Enjoy !
 </p>
   
   <h2>For Restaurants </h2> 

        <p> NinjaEats is an extension of a service by NinjaOS, the #1 Online ordering system for the F&B industry. Whether you are a home baker, cafe, restaurant with multiple outlets, club, cloud kitchen or mall. If you do F&B, we can help you grow & scale your business online.
 </p>
 <p  style={{ textAlign : "center"}}>  <img src={"/img/about-us.jpg"} alt={"About us"} className="image-max-width"  style={{ width: "100%" }} /> </p>
 
  
      </div>
      </section>
         </div>
		 <MainFooter />
		   </React.Fragment>   
)


export default About