import React from 'react'
import { Link } from 'react-router-dom'

const Footer = (props) => {
  const footerApplynow = (<section className="apply-now-section" id="applynowsection">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 mx-auto  d-flex align-items-center left-colm">
          <div className="apply-now-txt  text-left">
            <h2 className="section-heading">Are you a restaurant going online ? </h2>
          </div>
          <div className="apply-now-badges">
            <a rel="noopener noreferrer" href="https://www.ninjaos.com/getstarted" target="_blank" className="btn btn-outline btn-black">APPLY NOW</a>
          </div>
        </div>
        <div className="col-12 col-md-6 mx-auto  d-flex align-items-center right-colm">
          <div className="apply-now-txt  text-left">
            <h2 className="section-heading">List With Us</h2>
            <p>Display your promo code and get more customers</p>
          </div>
          <div className="apply-now-badges">
            <a rel="noopener noreferrer" href="https://www.ninjaos.com/getstarted" target="_blank" className="btn btn-outline btn-black">APPLY NOW</a>
          </div>
        </div>
      </div>
    </div>
  </section>);
  return (


    <div>
      {props.hideApplyNowBlock ? "" : footerApplynow}

      <footer>

        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="brand-colm">
                <Link className="navbar-brand  brand-logo js-scroll-trigger" to="/" title="the Promo Buddy"><img src={"/img/logo-white.jpg"} alt={"Images"} /></Link>
                <p>NinjaEats was launched with the aim of reaching out fantastic & irresistible deals out to consumers for Delivery, Takeaway, Dine In, Catering and Reservation. Best
deals from the best restaurants in Singapore.</p>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-0">
                    <span className="nav-header">Information</span>
                    <ul className="footer-nav">
                      <li>
                        <Link to="/about-us"> About Us</Link>
                      </li>
                      <li>
                        <Link to="/faq"> Frequently Asked Questions</Link>
                      </li>
                      <li>
                        <Link to="/terms-and-conditions"> Order Terms &amp; Delivery Charges</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">  Privacy Policy </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-0">
                    <span className="nav-header">Customer Service</span>
                    <ul className="footer-nav">
                      <li>
                        <a rel="noopener noreferrer" href="https://www.ninjaos.com/contact-us/" target="_blank"> Contact Us</a>
                      </li>
                       
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-0">
                    <span className="nav-header">Stay Connected</span>
                    <div className="d-flex align-items-center social-colm">
                      <h6 className="align-left">Follow us</h6>
                      <ul className="footer-social">
                        <li>
                          <Link to="/about-us"><img src="/img/social-fb.png" alt="images" /></Link>
                        </li>
                        <li>
                          <Link to="/about-us"><img src="/img/social-instagram.png" alt="images" /></Link>
                        </li>
                        <li>
                          <Link to="/about-us"><img src="/img/social-youtube.png" alt="images" /></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>Copyright 2019 | All Rights Reserved | Jankosoft Pte Ltd</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
};


export default Footer;
