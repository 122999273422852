import React from 'react'
import MainHeader from '../home/Header'
import MainFooter from '../home/Footer'
const pageHeader = (<header className="secondaryhead">
<div className="container h-100">
  <div className="row h-100">
    <div className="col-12 my-auto">
      <div className="header-content mx-auto">
        <h1 className="mb-1">Privacy Policy</h1>
        
      
      </div>
    </div>
  
  </div>
</div>
</header> );
 
const Privacy = () => (
 <React.Fragment>  
<MainHeader /> 
  <div>
 
    {pageHeader}
      <section className="cms-pages">
      <div className="container">
                
  <p> &nbsp;</p>
        <p>NinjaEats uses cookies and all visitors using the services of this website are automatically agreeable to our privacy policy. We do not in any way or form share the information gathered of our visitors to 3rd parties. 
 </p>
  
        <p  style={{ textAlign : "center"}}>  <img src={"/img/privacy.jpg"}  className="image-max-width" alt={"About us"} style={{ width: "100%" }} /> </p>  
		
   <p> &nbsp;</p>
 
  
      </div>
      </section>
         </div>
		  <MainFooter />
		   </React.Fragment> 
)


export default Privacy