import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Modal, ModalFooter, Alert } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { fetchPromotions } from '../../modules/promotions'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import Header from '../home/Header'
import Footer from '../home/Footer'
var _ = require('lodash');
const logoURl = "media/dev_team/company-logo/";
const promoImage = "media/dev_team/promotion/";
var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();
var thumpURL = "https://ccpl.ninjaos.com/timthumb.php?src=";
const defaultListingValue = 12;


class Promotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      promocode: "",
      promotionInfo: [],
      listCount: defaultListingValue,
    };
    this.toggle = this.toggle.bind(this);
    this.togglePromotion = this.togglePromotion.bind(this);
    this.loadmore = this.loadmore.bind(this);

  }
  onChange = ({ target: { promocode } }) => {
    this.setState({ promocode, copied: false });
  };
  onCopy = () => {
    this.setState({ copied: true });
  };
  toggle() {

    this.setState({
      modal: !this.state.modal,
      copied: false
    });
  }

  loadmore() {
    this.setState((state) => {
      return { listCount: state.listCount + defaultListingValue };
    })

  }
  togglePromotion(promotion) {
    this.setState({
      modal: !this.state.modal,
      promotionInfo: promotion,
      copied: false,
    });
  }
  componentWillMount() {
    this.props.fetchPromotions()
  }

  getRedirectURL(baseURL, value, tokens) {
    var value = parseInt(value);
    if (value === 1) {
      return baseURL + "#/promobuddytracking/token/" + tokens;
    } else {
      return baseURL + "promobuddytracking/token=" + tokens;
    }

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  orderAvailability(availability_name) {

    if (availability_name === "") {
      return <span className="tag">All Orders</span>
    } else if (typeof availability_name !== "undefined") {
      var partsOfStr = availability_name.split(',');
      return partsOfStr.map((data, index) => (<span className="tag" key={index}> {partsOfStr[index]} </span>));
    }


  }

  render() {
    var appdata = (typeof this.props.match !== "undefined") ? this.props.match.params.appData : "";

    const pageHeader = (<header className="secondaryhead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-1">View Promotions</h1>
              {/*<p>Any special message will show here</p> */}
            </div>
          </div>

        </div>
      </div>
    </header>);
    const isHideHeader = this.props.hideHeader;
    var promotionItems = "";
    if (this.props.promoLoading === true) {
      const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      promotionItems = numbers.map((number) => <div key={number} className="col-lg-3 col-md-4 col-sm-12 pr-0 promotion-item-colm"  >
        <div className="promotion-item card h-100 box-shadow">
          <div className="card-resturant-logo-img">
            <ReactPlaceholder type='textRow' ready={false} rows={1}> </ReactPlaceholder>
          </div>
          <div className="card-resturant-food-inners">
            <ReactPlaceholder type='rect' ready={false} color='#E0E0E0' style={{ width: 263, height: 263 }}> </ReactPlaceholder>

          </div>
          <div className="card-resturant-food-outers d-flex align-items-center">
            <ReactPlaceholder type='textRow' ready={false} rows={1}> </ReactPlaceholder>
          </div>
        </div>
      </div>)


    } else {
      var promotionDataLength;
      let params = appdata;
      var promotionData = this.props.promotions;
      if (typeof params !== "undefined" && params !== "" && typeof this.props.promotions !== "undefined") {
        var whereArr = this.props.promotions;
        var resultData = _.map(whereArr, function (res) {
          if (res.client_app_id === params) return res;
        });

        var fiResult = _.without(resultData, undefined)

        promotionData = fiResult;
      }
      if (typeof promotionData !== "undefined" && promotionData.length > 0) {
        promotionDataLength = promotionData.length;

        if (isHideHeader) {
          var data = promotionData;
          var chunk = _.chunk(data, this.state.listCount);
          var mapData = chunk[0];
        } else {
          mapData = promotionData;
        }

        promotionItems = mapData.map((promotion, index) => (
          <div className="col-lg-3 col-md-4 col-sm-12 pr-0 promotion-item-colm" key={index}>
            <div className="promotion-item card h-100 box-shadow">
              <div className="promotion-item-tags">{this.orderAvailability(promotion.availability_name)}  </div>
              <div className="card-resturant-logo-img">
                <img className="responsive-image" src={(promotion.client_logo !== "" ? thumpURL + promotion.host_url + logoURl + promotion.client_logo + "&w=183&h=46&q=80" : '/img/no-images/promotion-client-logo.jpg')} alt={promotion.client_name} />
              </div>
              <div className="card-resturant-food-inners">
                {/* <img className="resturant-logo"  alt="images"  src={( promotion.promotion_image !==null?  thumpURL + promotion.host_url + promoImage + promotion.promotion_image+"&w=315&h=225&q=80"  : "/img/no-images/promotion.jpg") } /> */}
                <h3 className="offer-tag">{promotion.promotion_name}</h3>
              </div>
              <div className="card-resturant-food-outers d-flex align-items-center">
                <button className="btn btn-promo-code d-flex align-items-center" onClick={this.togglePromotion.bind(this, promotion)}><img src="/img/badge-icon.png" alt="Images" />Use this Code</button>
              </div>
            </div>
          </div>

        ))
      } else {

        promotionItems = <div className="no-recods" style={{ width: "100%", textAlign: "center" }}><Alert color="info">  No records found   </Alert> </div>;
      }
    }


    const closeBtn = <button className="close" onClick={this.togglePromotion}><span aria-hidden="true">×</span></button>;
    return (
      <React.Fragment>
        {isHideHeader ? "" : <Header />}
        {isHideHeader ? "" : pageHeader}
        <section className="promotions bg-primary" id="promotions">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>Promotions</h2>
            </div>
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    {promotionItems}
                  </div>
                </div>

                {promotionDataLength > this.state.listCount && (<div className="readmore-content">
                  {(isHideHeader) ? <Link to="#" className="btn btn-outline btn-black" onClick={this.loadmore.bind(this)}>Read More</Link> : ""}
                </div>)}
              </div>
            </div>
          </div>
        </section>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} id="promocodeModalCenter">

          <div className="popup-promocode-food-info">
            <div className="promo-popup-top-section">
              {closeBtn}
              {/* <img className="promo-food-img" src= {( this.state.promotionInfo.promotion_image !==null? thumpURL+this.state.promotionInfo.host_url + promoImage + this.state.promotionInfo.promotion_image+"&w=854&h=372&q=80"  : "/img/no-images/promotion-popup.png") }   alt="images" />   */}
              <div className="row tags-discount-block">
                <div className="col-md-7">
                  <div className="promotion-item-tags">
                    {this.orderAvailability(this.state.promotionInfo.availability_name)}

                  </div>
                </div>
                {(this.state.promotionInfo.promotion_type === "percentage") ? <div className="col-md-5 ml-auto offer-tag"> <h3 className="offer-tag-txt"> {this.state.promotionInfo.promotion_percentage + "% OFF"}</h3></div> : ""}
				                {(this.state.promotionInfo.promotion_type === "fixed") ? <div className="col-md-5 ml-auto offer-tag"> <h3 className="offer-tag-txt">Offer upto ${this.state.promotionInfo.promotion_max_amt}</h3></div> : ""}
              </div>
            </div>
            <div className="promo-popup-middle-section">
              <h1>{this.state.promotionInfo.promotion_name}</h1>
              <span className="expiry-date-info">Valid till <Moment format="Do of MMM YYYY">{this.state.promotionInfo.promotion_end_date}</Moment></span>

              <p>{htmlToReactParser.parse(this.state.promotionInfo.promotion_long_desc)}</p>
            </div>
            <div className="promo-popup-bottom-section">
              <p>Goto <span className="restaurant-name-redtxt">{this.state.promotionInfo.client_name}</span> and paste the code at checkout</p>
              {this.state.copied ? <p><span className="copied-code-msg"> <Alert color="warning"> Copied! Promo Code.    </Alert></span></p> : null}

              <input className="input-copy-code" type="text" value={this.state.promocode = this.state.promotionInfo.promotion_name} alt="images" />
              <CopyToClipboard onCopy={this.onCopy} text={this.state.promocode}>
                <button className="btn btn-promo-code align-items-center"><img src="/img/copy-document-icon.png" alt="images" />Copy Code</button>
              </CopyToClipboard>

            </div>
          </div>
          <ModalFooter className="popup-promocode">

            <a href={this.getRedirectURL(this.state.promotionInfo.client_site_url, this.state.promotionInfo.client_ninja_pro_enable, this.state.promotionInfo.client_app_id)} rel="noopener noreferrer" target="_blank">GO TO ORDERING SITE AND PLACE ORDER <FontAwesomeIcon icon={faArrowRight} /></a>

          </ModalFooter>
        </Modal>
        {isHideHeader ? "" : <Footer />}

      </React.Fragment>
    )

  }

}

const mapStateToProp = state => ({
  promotions: state.promotions.items,
  imagepath: state.promotions.imagepath,
  promoLoading: state.promotions.loading,

})

export default connect(mapStateToProp, { fetchPromotions })(Promotions);
