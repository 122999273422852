import React, { Component } from 'react'
/*import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux' */
import { Link } from 'react-router-dom'
import Promotions from '../promotions'
//import Restaurants from '../restaurants'
import Header from '../home/Header'
import Footer from '../home/Footer'

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <div>
          <header className="masthead">

            <img src={"/img/banner-img1.jpg"} alt={"Images"} />

            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-md-6 my-auto">
                  <div className="header-content mx-auto">
                    {/* <h1 className="mb-1">Best Food Delivery Promo Codes & Deals In Town</h1> */}
                    {/* <p>Save More, Eat More, Redeem Your Promo Codes & Deals Today !</p> */}
                    {/* <Link to="/about-us" className="btn btn-outline btn-xl js-scroll-trigger">Read More</Link> */}
                  </div>
                </div>
              </div>
            </div>
          </header>
          <section className="apply-now-section" id="applynowsection">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 mx-auto  d-flex align-items-center left-colm flex-row-column">
                  <div className="apply-now-txt  text-left  padding-bottom-zero">
                    <h4 className="section-heading">Share Your Promo Code With Us</h4>
                  </div>
                  <div className="apply-now-badges  padding-top-zero">
                    <a rel="noopener noreferrer" href="https://www.ninjaos.com/getstarted" target="_blank" className="btn btn-outline btn-black ">APPLY NOW</a>
                  </div>
                </div>
                <div className="col-12 col-md-6 mx-auto  d-flex align-items-center right-colm flex-row-column">
                  <div className="apply-now-txt  text-left padding-bottom-zero">
                    <h4 className="section-heading">Are You Offering Delivery, Takeaway, Dine In, Catering & Reservation?</h4>
					{/*  <p>List Your Restaurant Here</p> */}
                  </div>
                  <div className="apply-now-badges  padding-top-zero">
                    <a rel="noopener noreferrer" href="https://www.ninjaos.com/getstarted" target="_blank" className="btn btn-outline btn-black">APPLY NOW</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12" style={{ textAlign : "center"}} >
                  <img src={"/img/how-works-banner.png"} alt={"How NinjaEats Works"} style={{ width: "100%" }} className="image-max-width" />
                </div>
              </div>
            </div>
          </section>
          <Promotions hideHeader="true" />
          {/* <Restaurants hideHeader="false" /> */}
        </div>

        <Footer hideApplyNowBlock="true" />
      </React.Fragment>)

  }
}
export default Home;
