import React, { Component } from 'react'
import { connect } from 'react-redux';
import {  Alert  } from 'reactstrap';
import { fetchRestaurants } from '../../modules/restaurants'
import {  Link } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import Header from '../home/Header'
import Footer from '../home/Footer'
var _ = require('lodash');
var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();

class Restaurants extends Component {
  
    componentWillMount(){
        this.props.fetchRestaurants()
    }
   componentDidMount() {
  window.scrollTo(0, 0)
}
loadRestaurants(){
	if(this.props.loding === true ) {
		return (<div> loding  </div>);
	}else {
		 	
	}
}	
	  
render() {

    const pageHeader = (  <header className="secondaryhead">
                        <div className="container h-100">
                          <div className="row h-100">
                            <div className="col-12 my-auto">
                              <div className="header-content mx-auto">
                                <h1 className="mb-1">View Restaurants</h1>
																{/*  <p>Any special message will show here</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </header> ); 
    const isHideHeader = this.props.hideHeader;    
    var restaurantItems = "";

	 if(this.props.restLoding === true ) {
		    const numbers = [1, 2, 3, 4, 5,6,7,8,9,10,11,12];
		 restaurantItems =  numbers.map((number) =><div key={number} className="col-lg-6 col-md-6 col-sm-12 pr-0 restaurant-item-colm" >
         <div className="restaurant-item card h-100 box-shadow">
           <div className="container-fluid h-100">
             <div className="row h-100">
               <div className="col-md-5 p-0  prmobudy-resturant-left-colm">  
                <ReactPlaceholder type='rect' ready={false} color='#E0E0E0' style={{ width: 200, height: 228 }}> </ReactPlaceholder>          
                       
               </div>
               <div className="col-md-7 align-self-center prmobudy-resturant-right-colm">
                 <h3 className="card-title">               <ReactPlaceholder type='textRow' ready={false} rows={5}> </ReactPlaceholder>  </h3>
                 <div className="prmobudy-rf-categories mb-1">
                 
                   <span>               <ReactPlaceholder type='textRow' ready={false} rows={1}> </ReactPlaceholder>  </span>
                 </div>
                 <div className="prmobudy-rf-locations mb-1">
                                   <ReactPlaceholder type='textRow' ready={false} rows={5}> </ReactPlaceholder>  
                 </div>
                 <div className="card-prmobudy-resturant-outers mt-3 d-flex justify-content-between">                          
  <ReactPlaceholder type='rect' ready={false} color='#E0E0E0' style={{ width: 292, height: 50 }}> </ReactPlaceholder>         
                   <ReactPlaceholder type='rect' ready={false} color='#E0E0E0' style={{ width: 292, height: 50 }}> </ReactPlaceholder>         
                 </div>  
               </div>
             </div>
           </div>          
         </div>
       </div>)
	 }else {
		 
		if(typeof this.props.restaurants !== "undefined"   ) { 
		  if(isHideHeader) { 
		 var data = this.props.restaurants;
         var chunk = _.chunk(data, 12);
		  var mapData = chunk[0];
		 }else {
			   mapData = this.props.restaurants;
		 }
	 
     restaurantItems =    mapData.map(restaurant => (
      
         <div className="col-lg-3 col-md-4 col-sm-12 pr-0 restaurant-item-colm" key={restaurant.res_app_id}>
         <div className="restaurant-item card box-shadow">
               <div className="col-md-12 p-0  prmobudy-resturant-left-colm">  
                <Link to={"listing/"+restaurant.res_slug} > <img className="resturant-img" alt="images" src= { ( (restaurant.res_cover_image !== "" && restaurant.res_cover_image !== null ) ?  this.props.coverimagepath + restaurant.res_cover_image  : "/img/no-images/client.jpg" )} />            
                 <img className="resturant-log"  alt="images"  src={ ( (restaurant.res_logo !== null && restaurant.res_logo !== "" ) ?   this.props.restaurantlogopath + restaurant.res_logo      : "/img/no-images/no-image-183x46.jpg" )} />                
				 </Link>
               </div>
               <div className="col-md-12 align-self-center prmobudy-resturant-right-colm">
                 <h3 className="card-title"> <Link className="res_name" to={"listing/"+restaurant.res_slug} >{restaurant.res_name} </Link></h3>
					 { restaurant.res_cuisine !== "" &&  <div className="prmobundy-rf-categories mb-1">
                  <span className="rest-info-left"><img src="img/spoon-icon.png"  alt="images"  /></span> 
					 <span className="rest-info-right">{htmlToReactParser.parse(restaurant.res_cuisine)}</span>   
                 </div> }
                 { restaurant.res_phone_no !== "" &&  <div className="prmobudy-rf-locations mb-1">
                 <span className="rest-info-left"> <img src="img/telephone-icon.png"  alt="images"  /></span>
                   <span className="rest-info-right">{restaurant.res_phone_no}</span>
                 </div> }
                 {/* <div className="card-prmobudy-resturant-outers mt-3 d-flex justify-content-between">                          
                   <Link   to={"/promotions/"+restaurant.res_app_id}   className="btn btn-promotions">Promotions</Link>
                   <a href={restaurant.res_site_url} className="btn btn-order-now" rel="noopener noreferrer"  target="_blank">Order Now</a>
                 </div>   */}
               </div>
         </div>
       </div>
	))  
		}else {
		 
		  restaurantItems = <div className="no-recods" style={{width:"100%", textAlign:"center"}}><Alert color="info">  No records found   </Alert> </div>
	  }
	 }
	
    return (  
      <React.Fragment>     
	    {isHideHeader ? "" :<Header /> }  
      {isHideHeader ? "" :pageHeader }     
      <section className="restaurants bg-secondary" id="restaurants">
        <div className="container-fluid">
          <div className="section-heading">
            <h2>Restaurants</h2>        
          </div>
          <div className="row">          
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row row-eq-height">
                 {restaurantItems}
                </div>
					{ (isHideHeader)?      <div className="readmore-content">
                <Link className="btn btn-outline btn-black" to="/restaurants">Read More</Link>               
					</div>  : ""}
              </div>
            </div>
          </div>
        </div>
        </section>
		
		      {isHideHeader ? "" : <Footer /> }     
        </React.Fragment>
    )
   
  }
 
}



const mapStateToProp = state => ({
    restaurants:state.restaurants.items,
    restLoding:state.restaurants.loading,
	returnSts : state.restaurants.returnSts,
	coverimagepath : state.restaurants.coverimagepath,
	restaurantlogopath : state.restaurants.restaurantlogopath,
   
})



export default connect(mapStateToProp,{fetchRestaurants}) (Restaurants);
