import React, { Component } from 'react'
import { connect } from 'react-redux';
import {  Modal,  ModalFooter,Alert  } from 'reactstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { fetchPromotions } from '../../modules/promotions'
import { fetchRestaurants } from '../../modules/restaurants'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import Header from '../home/Header'
import Footer from '../home/Footer'
import { Scrollbars } from 'react-custom-scrollbars';


var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();
var _ = require('lodash');
const logoURl = "media/dev_team/company-logo/";
const promoImage = "media/dev_team/promotion/";


class Restaurantdetails extends Component {
  constructor(props ) {
    super(props);
    this.state = {
      modal: false,
      promocode: "",
      promotionInfo: []
    };
    this.toggle = this.toggle.bind(this);
    this.togglePromotion = this.togglePromotion.bind(this);
	 
  }
  onChange = ({target: {promocode}}) => {
    this.setState({promocode, copied: false});
  };
  onCopy = () => {
    this.setState({copied: true});
  };
  toggle() {
    
    this.setState({
      modal: !this.state.modal,
      copied: false
    });
  }
  togglePromotion(promotion) {
    this.setState({
      modal: !this.state.modal,
      promotionInfo: promotion,
      copied : false,
    });
  }
    componentWillMount(){        
        this.props.fetchPromotions() 
        this.props.fetchRestaurants()
	
    }
componentDidMount() {
  window.scrollTo(0, 0)
}



getRedirectURL(baseURL,value,tokens ) {
	var value =  parseInt(value);
 
	if(value <= 1 ) {
		return baseURL;
	}else if( value === 2 || value === 4) {
		return baseURL+"#/promobuddytracking/token/"+tokens ;
	}else {
			return baseURL+"promobuddytracking/token="+tokens;
	}
	
}

orderAvailability(availability_name) {
 
	if( availability_name === "" ) {
		 return        <span className="tag">All Orders</span>  
	}else if (typeof availability_name !== "undefined") {
	   var partsOfStr = availability_name.split(',');
       return partsOfStr.map((data,index) => ( <span className="tag" key={index}> {partsOfStr[index]} </span> ));
	}
	

}	

getCoverImage(image,path) {
	
	return  (image === null || image === "" )? "/img/no-images/no-image-1666x454.JPG" :  path + image;
}

getlogoImage(image,path) {
	
	return  (image === null || image === "" )? "/img/no-images/no-image-183x46.jpg" : path + image ;
}

sitereturnUrl(url) {
	
	url = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
	 return url;
}

    
render() { 
    
	    let pageSlug = this.props.match.params.pageSlug;
		 var restaurantsDetails = this.props.restaurants;
		 if(typeof pageSlug !== "undefined" && pageSlug !== "" && typeof restaurantsDetails !== "undefined" ){
				  var whereArrData = restaurantsDetails ;
					var resultData = _.map(whereArrData, function(resData) {
						if (resData.res_slug === pageSlug) return resData;
					});
                 var fiResultdata = _.without(resultData, undefined)
	              restaurantsDetails = fiResultdata;
			 } 
	
	
  /*  const pageHeader = (<header className="restaurantdetails-header">

    <div className="resturant-sliders mx-auto">
    <img src="" />
    </div>
   
</header> ); 
    const isHideHeader = this.props.hideHeader; */
	var promotionItems = "";
	if(this.props.promoLoading === true ) {
		const numbers = [1, 2, 3, 4, 5,6,7,8,9,10,11,12];
		 promotionItems =  numbers.map((number) => <div  key={number}className="col-12 col-xl-4 col-md-6 col-sm-12 pr-0 promotion-item-colm"  >
        <div className="promotion-item card h-100 box-shadow">
          <div className="card-resturant-logo-img">
            <ReactPlaceholder type='textRow' ready={false} rows={1}> </ReactPlaceholder>
          </div>                      
          <div className="card-resturant-food-inners">
		 <ReactPlaceholder type='rect' ready={false} color='#E0E0E0' style={{ width: 263, height: 263 }}> </ReactPlaceholder>
	 	  
          </div>
          <div className="card-resturant-food-outers d-flex align-items-center">                          
           <ReactPlaceholder type='textRow' ready={false} rows={1}> </ReactPlaceholder>
          </div>  
        </div>
      </div> )
    
 
	}else {
		 var params = "";
		   if(typeof restaurantsDetails !== undefined &&  restaurantsDetails.length > 0) { 	
               params = restaurantsDetails[0].res_app_id; 
			 
		   }
		 
		 
		 var promotionData = [];
		 if(typeof params !== "undefined" && params !== "" && typeof this.props.promotions !== "undefined" ){
				  var whereArr =  this.props.promotions ;
					var resultDatapars = _.map(whereArr, function(res) {
						if (res.client_app_id === params) return res;
					});
 
                 var fiResult = _.without(resultDatapars, undefined)
				 
	              promotionData = fiResult;
			 } 
	 if(  typeof promotionData !== "undefined" && promotionData.length > 0    ) { 
 
		 var mapData = promotionData;
	 
		  promotionItems = mapData.map((promotion,index) => (
      <div className="col-12 col-xl-4 col-md-6 col-sm-12 pr-0 promotion-item-colm" key={index}>
        <div className="promotion-item card h-100 box-shadow">
          <div className="card-resturant-logo-img">
            <img className="responsive-image" src={ (promotion.client_logo !==""? promotion.host_url + logoURl + promotion.client_logo : 'img/no-images/promotion-client-logo.png' )}  alt={promotion.client_name} />
          </div>                      
          <div className="card-resturant-food-inners">
            <img className="resturant-logo"  alt="images"  src={( promotion.promotion_image !==null? promotion.host_url + promoImage + promotion.promotion_image  : "/img/no-images/client.jpg") } />
			<div className="promotion-item-tags">{this.orderAvailability(promotion.availability_name)}  </div>
            <h3 className="offer-tag">{promotion.promotion_name}</h3>
          </div>
          <div className="card-resturant-food-outers d-flex align-items-center">                          
            <button  className="btn btn-promo-code d-flex align-items-center" onClick={this.togglePromotion.bind(this,promotion)}><img src="/img/badge-icon.png" alt="Images" />Use this Code</button>
          </div>  
        </div>
      </div>
     
	 )) }else {
		 
		  promotionItems = <div className="no-recods" style={{width:"100%", textAlign:"center"}}><Alert color="info">  Currently no promo code available, Please visit after some time </Alert> </div>;
	 }
	}
	
   
    const closeBtn = <button className="close"  onClick={this.togglePromotion}><span aria-hidden="true">X</span></button>;
  
   if(typeof restaurantsDetails !== undefined &&  restaurantsDetails.length > 0) { 	
  var disData = restaurantsDetails[0];
   
  return (
    <React.Fragment>     
     <Header />
	{/* {isHideHeader ? "" :pageHeader }  */}

<header className="restaurantdetails-header">

    <div className="resturant-sliders mx-auto">
    <img src={this.getCoverImage(disData.res_cover_image_1,this.props.coverimagepath)} alt="loading" />
    </div>
   
</header> 
	 
      <section className="restaurantdetails-container">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9">
            <div className="restaurant-title-block">
                <div className="restaurant-title-left">
                   <span className="rest-logo-circle">
                   <img src={this.getlogoImage(disData.res_logo_1,this.props.restaurantlogopath)} alt={'logo'} />
                   </span>
                </div>
                <div className="restaurant-title-right">
                 <h2>{disData.res_name}</h2>
                 <a href={this.getRedirectURL(disData.res_site_url,disData.res_site_type,disData.res_app_id)} rel="noopener noreferrer"  target="_blank">{this.sitereturnUrl(disData.res_site_url)}</a>
                </div>
            </div>
            <div className="restaurant-content-block-outer">
            <Scrollbars
                style={{ height: 240 }}>
                <div className="restaurant-content-block">
                {htmlToReactParser.parse(disData.res_desc)}
                </div>
                </Scrollbars>
            </div>
            <section className="promotions" id="promotions">
                 
                        <div className="container-fluid">
                          <div className="row">               
                          {promotionItems}         
                          </div>
                        </div>
                  
                </section>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
          <div className="restaurantdetails-right-block">
              <div className="order-btn-block">
                <a className="btn btn-order-now"  target="_blank" rel="noopener noreferrer" href={this.getRedirectURL(disData.res_site_url,disData.res_site_type,disData.res_app_id)}><img src={"/img/take-away-icon.png"} alt={"Images"} />Order Now</a>              
              </div>
              <div className="map-block">
                <a target="_blank" rel="noopener noreferrer" href={disData.res_map_url}><img src={"/img/location-map.png"} alt={"Images"} /></a>
              </div>
           
              <div className="contacts-block">
                <ul>
                { disData.res_cuisine !== "" &&  <li><span className="rest-info-left"><img src={"/img/spoon-icon.png"} alt={"Images"} /></span><span className="rest-info-right">{htmlToReactParser.parse(disData.res_cuisine)}</span></li> }
				{ disData.res_phone_no !=="" && <li><span className="rest-info-left"><img src={"/img/telephone-icon.png"} alt={"Images"} /></span><span className="rest-info-right">{disData.res_phone_no}</span></li> }
				{ disData.res_email !=="" && <li><span className="rest-info-left"><img src={"/img/mail-icon.png"} alt={"Images"} /> </span><span className="rest-info-right">{disData.res_email}</span></li> }
				{ disData.res_outlet !== "" &&  <li><span className="rest-info-left"><img src={"/img/placeholder.png"} alt={"Images"} /></span><span className="rest-info-right">{htmlToReactParser.parse(disData.res_outlet)}</span></li> }
				{ disData.res_timing !== "" && <li><span className="rest-info-left"><img src={"/img/alarm-clock-icon.png"} alt={"Images"} /></span><span className="rest-info-right">Shop Opening Time <span>{htmlToReactParser.parse(disData.res_timing)}</span></span> </li>  }
                  
                </ul>
              
              </div>
            </div>
          
          <div className="restaurantdetails-right-bottom">
            <ul className="restaurant-imgs">
                <li className="col-xs-4 col-4"><a rel="noopener noreferrer" href={disData.res_site_url} target="_blank"><img alt={""} src={"/img/restaurant-img1.png"} /></a></li>
                <li className="col-xs-4 col-4"><a  rel="noopener noreferrer" href={disData.res_site_url} target="_blank"><img alt="" src={"/img/restaurant-img2.png"} /></a></li>
                <li className="col-xs-4 col-4"><a  rel="noopener noreferrer" href={disData.res_site_url} target="_blank"><img alt="" src={"/img/restaurant-img1.png"} /></a></li>
                <li className="col-xs-4 col-4"><a rel="noopener noreferrer"  href={disData.res_site_url} target="_blank"><img alt="" src={"/img/restaurant-img1.png"} /></a></li>
                <li className="col-xs-4 col-4"><a rel="noopener noreferrer" href={disData.res_site_url} target="_blank"><img alt="" src={"/img/restaurant-img2.png"} /></a></li>
                <li className="col-xs-4 col-4"><a rel="noopener noreferrer"  href={disData.res_site_url} target="_blank"><img alt="" src={"/img/restaurant-img1.png"} /></a></li>
            </ul>
          </div>
          </div>  
        </div>
      </div>
      </section>
     
    
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} id="promocodeModalCenter">
          
          <div className="popup-promocode-food-info">         
        <div className="promo-popup-top-section">
          {closeBtn}
          <img className="promo-food-img" src= {( this.state.promotionInfo.promotion_image !==null? this.state.promotionInfo.host_url + promoImage + this.state.promotionInfo.promotion_image  : "/img/no-images/promotion-popup.png") }   alt="images" />  
          <div className="row tags-discount-block">
            <div className="col-md-7">
              <div className="promotion-item-tags">
			  {this.orderAvailability(this.state.promotionInfo.availability_name)}
              
              </div>
            </div>
			{ (this.state.promotionInfo.promotion_type === "percentage")?   <div className="col-md-5 ml-auto offer-tag"> <h3 className="offer-tag-txt"> {this.state.promotionInfo.promotion_percentage+"% OFF"}</h3></div> : "" }
          </div>
        </div>
        <div className="promo-popup-middle-section">
          <h1>{this.state.promotionInfo.promotion_name}</h1>
          <span className="expiry-date-info">Valid till <Moment format="Do of MMM YYYY">{this.state.promotionInfo.promotion_end_date}</Moment></span>
          
          <p>{htmlToReactParser.parse(this.state.promotionInfo.promotion_long_desc)}</p>
        </div>
        <div className="promo-popup-bottom-section">
          <p>Goto <span className="restaurant-name-redtxt">{this.state.promotionInfo.client_name}</span> and paste the code at checkout</p>
          {this.state.copied ? <p><span className="copied-code-msg"> <Alert color="warning"> Copied! Promo Code.    </Alert></span></p> : null}
          
          <input className="input-copy-code" type="text" value={this.state.promocode = this.state.promotionInfo.promotion_name  } alt="images" />
          <CopyToClipboard onCopy={this.onCopy} text={this.state.promocode}>
            <button className="btn btn-promo-code align-items-center"><img src="/img/copy-document-icon.png"  alt="images"  />Copy Code</button>
          </CopyToClipboard>
          
        </div>
      </div>
          <ModalFooter className="popup-promocode">
 
          <a  href={this.getRedirectURL(disData.res_site_url,disData.res_site_type,disData.res_app_id)} rel="noopener noreferrer"  target="_blank">GO TO ORDERING SITE AND PLACE ORDER <FontAwesomeIcon icon={faArrowRight} /></a>
 
          </ModalFooter>
        </Modal>
		
		 <Footer />
    </React.Fragment>     
    )
	
   } else {
	   
	    return (<div className="no-recods" style={{width:"100%", textAlign:"center", paddingTop: "200px",paddingBottom: "200px"}}> <Header /><Alert color="info">  No records found   </Alert>  <Footer /> </div>);
   }
   
  }
 
}



const mapStateToProp = state => ({
  promotions:state.promotions.items,
  imagepath:state.promotions.imagepath,
  promoLoading:state.promotions.loading,
  restaurants:state.restaurants.items,
  restLoding:state.restaurants.loading,
  returnSts : state.restaurants.returnSts,
  coverimagepath : state.restaurants.coverimagepath,
  restaurantlogopath : state.restaurants.restaurantlogopath,
  
})



export default connect(mapStateToProp,{fetchPromotions,fetchRestaurants}) (Restaurantdetails);
