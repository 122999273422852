import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar } from 'reactstrap';

const Header = props => (
  <Navbar id="mainNav" color="light" light expand="lg" sticky="top">
    <div className="container-fluid">
      <Link className="navbar-brand brand-logo" to="/home"><img src={"/img/logo-black.jpg"} alt={"Images"} /></Link>
      <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        Menu
            <i className="fas fa-bars" />
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link js-scroll-trigger" to="/promotions">View Promotions</Link>
          </li>
          {/*   <li className="nav-item">
                 <Link className="nav-link js-scroll-trigger" to="/restaurants">View Resturants</Link>
</li> */}
        </ul>
        <a className="btn btn-howtolist" rel="noopener noreferrer" target="_blank" href="http://www.ninjaos.com/the-promo-buddy-app/">How to list</a>

      </div>
    </div>
  </Navbar >
)


export default Header;
