import React from 'react'
import { Route , Switch } from 'react-router-dom'
import Home from '../home'
import Maintenance from '../home/Maintenance'
import Restaurants from '../restaurants'
import Promotions from '../promotions'
import About from '../about' 
import Privacy from '../about/Privacy'
import Terms from '../about/Terms'
import Restaurantdetails from '../restaurantdetails'
import Faq from '../faq'
 
const App = () => (
  <div>
    {/* Navigation */}
  
     
    <main>
	<Switch>
	  <Route  exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route  path="/restaurants" component={Restaurants} />
      <Route  path="/promotions/:appData"    component={Promotions}/>
      <Route  path="/promotions" component={Promotions} />
      <Route  path="/about-us" component={About} />
	  <Route  path="/privacy-policy" component={Privacy} /> 
	   <Route  path="/terms-and-conditions" component={Terms} />
	  <Route  path="/about-us" component={About} />
      <Route  path="/listing/:pageSlug" component={Restaurantdetails} />
      <Route  path="/faq" component={Faq} />
	  </Switch>
    </main>
    
  </div>
)

export default App
